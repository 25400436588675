export const ADMINISTRATION_DEFINITIONS = {
  lizenznehmer: { icon: 'roofing', captionPlural: 'Lizenznehmer' },
  benutzer: { icon: 'person', captionPlural: 'Benutzer' },
  rzabrechnungskreis: {
    icon: 'hdr_auto',
    captionPlural: 'RZ-Abrechnungskreise',
  },
  niederlassung: { icon: 'business', captionPlural: 'Niederlassungen' },
  termin: { icon: 'today', captionPlural: 'Termine' },
  mandant: { icon: 'contacts', captionPlural: 'Verbuchungsbetriebe' },
  beschaeftigungsbetrieb: {
    icon: 'location_city',
    captionPlural: 'Beschäftigungsbetriebe',
  },
  finanzamt: { icon: 'local_atm', captionPlural: 'Finanzämter' },
  sozialkasse: { icon: 'volunteer_activism', captionPlural: 'Sozialkassen' },
  unfallversicherung: {
    icon: 'contact_emergency',
    captionPlural: 'Unfallversicherungen',
  },
  abrechnungskreis: { icon: 'hdr_auto', captionPlural: 'Abrechnungskreise' },
  konzern: { icon: 'account_balance', captionPlural: 'Konzerne' },
};
